/* eslint-disable react/no-danger */
/* eslint-disable import/prefer-default-export */

import React from "react";
import { PropTypes } from "prop-types";
import { css, theme } from "twin.macro";
import { useWindowDimensions } from "~hooks";

/**
 * -----------------------------------------------------------------------------
 * Receive a h1-h6 node from the style guide. The type of node returned is
 * determined by the level supplied.
 * @param  {object} _css      Additional Emotion/Tailwind CSS
 * @param  {node}   children  Inner text
 * @param  {string} level     The heading level (accepts 1, 2, 3, 4, 5, 6)
 * @param  {string} font      The font style (accepts 1, 2, 3, 4, b1, b2, b3)
 * @return {node}             The resulting <h?> node
 */
export const Head = ({ _css, children, font, level, fontKeyOverride }) => {
  const { screen } = useWindowDimensions();

  let fontFamily = theme`fontFamily`.head.join();
  let fontKey = `h${font}`;

  if (font?.toString()?.indexOf(`b`) !== -1) {
    fontFamily = theme`fontFamily`.body.join();
    fontKey = font;
  } else {
    fontFamily = theme`fontFamily`.head.join();
  }

  if (fontKeyOverride) fontKey = fontKeyOverride;

  const tailwindConfig = theme`fontSize`[
    `${fontKey}${screen !== `xs` ? `-${screen}` : ``}`
  ];

  let fontCSS = {};

  if (tailwindConfig?.[0] && tailwindConfig?.[1]) {
    fontCSS = {
      fontFamily,
      fontSize: tailwindConfig[0],
      ...tailwindConfig[1]
    };
  }

  const H = `h${level}`;

  return (
    <H
      css={[
        ..._css,
        css`
          :target:before {
            content: "";
            display: block;
            height: 100px;
            margin: -100px 0 0;
          }
          ${fontCSS}
        `
      ]}
      id={
        [`string`, `number`].includes(typeof children)
          ? children?.replace(/\s+/g, `-`).toLowerCase()
          : null
      }
      // id={children?.replace(/\s+/g, `-`).toLowerCase()}
    >
      {children}
    </H>
  );
};
Head.defaultProps = {
  _css: [],
  font: `1`,
  level: `2`,
  fontKeyOverride: null
};
Head.propTypes = {
  _css: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.node.isRequired,
  font: PropTypes.string,
  level: PropTypes.string,
  fontKeyOverride: PropTypes.string
};

/**
 * -----------------------------------------------------------------------------
 * Receive a <p> (body) node from the style guide.
 * @param  {node}   children  Inner text
 * @param  {string} font      The font style (accepts 1, 2, 3)
 * @param  {object} _css      Additional Emotion/Tailwind CSS
 * @return {node}             The resulting <p> node
 */
export const Body = ({ children, font, _css, serif, allowHTML }) => {
  const { screen } = useWindowDimensions();

  let fontFamily;
  let fontKey = `b${font}`;

  if (serif) {
    fontFamily = theme`fontFamily`.serif.join();
  } else if (font?.toString()?.indexOf(`h`) !== -1) {
    fontFamily = theme`fontFamily`.head.join();
    fontKey = font;
  } else {
    fontFamily = theme`fontFamily`.body.join();
  }

  const tailwindConfig = theme`fontSize`[
    `${fontKey}${screen !== `xs` ? `-${screen}` : ``}`
  ];

  let fontCSS = {};

  if (tailwindConfig?.[0] && tailwindConfig?.[1]) {
    fontCSS = {
      fontFamily,
      fontSize: tailwindConfig[0],
      ...tailwindConfig[1]
    };
  }

  return (
    <p
      css={[
        ..._css,
        css`
          ${fontCSS}
        `
      ]}
    >
      {allowHTML ? (
        <span
          css={[
            css`
              a {
                text-decoration: underline;
                text-decoration-thickness: 1px;
              }
            `
          ]}
          dangerouslySetInnerHTML={{ __html: children }}
        />
      ) : (
        children
      )}
    </p>
  );
};
Body.defaultProps = {
  _css: [],
  font: `1`,
  serif: false,
  allowHTML: true
};
Body.propTypes = {
  _css: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.node.isRequired,
  font: PropTypes.string,
  serif: PropTypes.bool,
  allowHTML: PropTypes.bool
};

/**
 * -----------------------------------------------------------------------------
 * Receive a button-styled node from the style guide.
 * @param  {node}   children  Inner text
 * @param  {object} _css      Additional Emotion/Tailwind CSS
 * @param  {string} node      Node type; <span> by default
 * @return {node}             The resulting <?> node (defaults to <span>)
 */
export const Button = ({ children, _css, node }) => {
  const { screen } = useWindowDimensions();

  const tailwindConfig = theme`fontSize`[
    `button${screen !== `xs` ? `-${screen}` : ``}`
  ];

  let fontCSS = {};

  if (tailwindConfig?.[0] && tailwindConfig?.[1]) {
    fontCSS = {
      fontFamily: theme`fontFamily`.body.join(),
      fontSize: tailwindConfig[0],
      ...tailwindConfig[1]
    };
  }

  const B = `${node}`;

  return (
    <B
      css={[
        ..._css,
        css`
          ${fontCSS}
        `
      ]}
    >
      {children}
    </B>
  );
};

Button.defaultProps = {
  _css: [],
  node: `span`
};
Button.propTypes = {
  _css: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.node.isRequired,
  node: PropTypes.string
};

/**
 * -----------------------------------------------------------------------------
 * Receive a <p> (caption) node from the style guide.
 * @param  {node}   children  Inner text
 * @param  {object} _css      Additional Emotion/Tailwind CSS
 * @param  {string} node      Node type; <span> by default
 * @return {node}             The resulting node
 */
export const Caption = ({ children, _css, node }) => {
  const { screen } = useWindowDimensions();

  const tailwindConfig = theme`fontSize`[
    `caption${screen !== `xs` ? `-${screen}` : ``}`
  ];

  let fontCSS = {};

  if (tailwindConfig?.[0] && tailwindConfig?.[1]) {
    fontCSS = {
      fontFamily: theme`fontFamily`.body.join(),
      fontSize: tailwindConfig[0],
      ...tailwindConfig[1]
    };
  }

  const C = `${node}`;

  return (
    <C
      css={[
        ..._css,
        css`
          ${fontCSS}
        `
      ]}
    >
      <span dangerouslySetInnerHTML={{ __html: children }} />
    </C>
  );
};

Caption.defaultProps = {
  _css: [],
  node: `span`
};
Caption.propTypes = {
  _css: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.node.isRequired,
  node: PropTypes.string
};
