/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React from "react";
import tw, { css } from "twin.macro";
import * as T from "~styles/Typography.jsx";
import { Button, LineSwipe } from "~components";

/** ============================================================================
 * @component
 * A card displaying some information with a copy text button
 */
const InfoCard = ({
  _css,
  backgroundColor,
  headingFontColor,
  fontColor,
  heading,
  subsections
}) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  let cardText = heading;

  // ---------------------------------------------------------------------------
  // render

  return (
    <div
      css={[
        css`
          background-color: ${backgroundColor?.hex || `#ffffff`};

          ${_css};
        `,
        tw`relative flex-1 flex flex-col p-6`
      ]}
    >
      <T.Head
        _css={[
          css`
            color: ${headingFontColor?.hex || `#000000`};
          `,
          tw`mt-4`
        ]}
        font="3"
      >
        {heading}
      </T.Head>

      <div css={[tw`w-32 relative block pt-6`]}>
        <LineSwipe
          color={headingFontColor?.hex === `#ffffff` && `#ffffff`}
          delay={500}
          duration={2000}
        />
      </div>

      {subsections?.map((subsection) => {
        cardText = `${cardText}\n\n${subsection.heading}\n\n${subsection.body}`;

        return (
          <article css={[tw`relative mt-3`]}>
            <T.Head
              _css={[
                css`
                  color: ${fontColor?.hex || `#000000`};
                `,
                tw`font-bold mt-4`
              ]}
              font="b1"
              level="4"
            >
              {subsection.heading}
            </T.Head>

            <T.Body
              _css={[
                css`
                  color: ${fontColor?.hex || `#000000`};
                  white-space: pre-wrap;
                `,
                tw`w-4/5 relative mt-4`
              ]}
              font="3"
            >
              {subsection.body}
            </T.Body>
          </article>
        );
      })}

      <div
        css={[tw`w-full mt-8 relative flex flex-grow justify-end items-end`]}
      >
        <Button
          _css={[tw`h-14 relative block border`]}
          text="Copy Text"
          icon="cross"
          onClick={() => {
            navigator.clipboard.writeText(cardText);
          }}
        />
      </div>
    </div>
  );
};

export default InfoCard;
