/* eslint-disable react/prop-types */

import React, { useState, useRef, useEffect } from "react";
import tw, { css } from "twin.macro";
import * as A from "~styles/Animations.jsx";
import * as T from "~styles/Typography.jsx";
import { Go, Image } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import { getColor } from "~utils/helpers";

import { ReactComponent as ArrowRight } from "~assets/svg/arrow-right.svg";

/** ============================================================================
 * @component
 * A carousel of cards that expose text when hovered.
 */
const LinkCard = ({ data: { link, subheading, heading, body, image } }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [hovering, setHovering] = useState(false);
  const [imageHeight, setImageHeight] = useState(0);
  const imageRef = useRef(null);

  useEffect(() => {
    setImageHeight(imageRef?.current?.clientHeight);
  }, [imageRef?.current?.clientHeight]);
  // ---------------------------------------------------------------------------
  // render

  return (
    <article
      key={heading}
      css={[
        css`
          padding-top: calc(${imageHeight}px + 10rem);

          ${MEDIA_QUERIES?.desktop} {
            padding-top: calc(${imageHeight}px + 13rem);
          }

          ${MEDIA_QUERIES?.large} {
            padding-top: calc(${imageHeight}px + 18rem);
          }

          ${MEDIA_QUERIES?.wide} {
            padding-top: calc(${imageHeight}px + 17rem);
          }
          ${MEDIA_QUERIES?.ultrawide} {
            padding-top: calc(${imageHeight}px + 14rem);
          }

          ${MEDIA_QUERIES?.extremewide} {
            padding-top: calc(${imageHeight}px);
          }

          ${MEDIA_QUERIES?.hoverable} {
            &:hover {
              svg {
                path {
                  fill: ${getColor(`blue-bright`)};
                }
              }
            }
          }
        `,
        tw`relative block overflow-hidden cursor-pointer md:mr-4 mb-8 md:mb-0 bg-grey text-blue-deep`
      ]}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <Go to={link || `/`}>
        <div css={[tw`w-full h-full absolute top-0 right-0 bottom-0 left-0`]}>
          <div
            css={[
              css``,
              tw`relative overflow-hidden pt-10 md:pt-8 lg:pt-16 pr-8 md:pr-5 lg:pr-10 pl-8 md:pl-5 lg:pl-10`
            ]}
          >
            <header css={[tw`w-4/5 md:w-3/4 relative`]}>
              <T.Head
                font="6"
                _css={[
                  css`
                    color: ${getColor(`black`)};
                  `
                ]}
              >
                {subheading}
              </T.Head>

              <T.Head _css={[tw`mt-6 font-bold`]} font="3">
                {heading || `Lorem ipsum`}
              </T.Head>
            </header>
            <div>
              <T.Body
                _css={[
                  css`
                    transition: all 0.3s ${A.EASING_CUBIC};

                    display: none;

                    ${MEDIA_QUERIES?.desktop} {
                      opacity: ${hovering ? `1` : `0`};
                      display: block;
                    }
                  `,
                  tw`w-3/4 relative mt-4 md:mt-6 text-black`
                ]}
                font="2"
              >
                {body || `Lorem ipsum`}
              </T.Body>
            </div>
          </div>

          <div
            css={[
              css`
                transition: transform 0.3s ${A.EASING_CUBIC};

                transform: translate3d(0, ${hovering ? `25%` : `0`}, 0);

                height: 80%;

                ${MEDIA_QUERIES?.desktop} {
                  height: 65%;
                }
              `,
              tw`absolute bottom-0 right-0 left-0 z-10 flex flex-col justify-between pointer-events-none`
            ]}
          >
            <div
              css={[
                css`
                  //
                `,
                tw`w-full relative flex items-end justify-end`
              ]}
            >
              <div
                css={[
                  css``,
                  tw`w-12 h-0 mt-2 relative flex items-start justify-center`
                ]}
              >
                <ArrowRight css={[tw`w-6`]} color="black" fill="black" />
              </div>
            </div>

            <figure css={[css``, tw`w-full relative`]} ref={imageRef}>
              <Image image={image} />
            </figure>
          </div>
        </div>
      </Go>
    </article>
  );
};

export default LinkCard;
