/* eslint-disable react/prop-types */

import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Image = ({ _css, image, alt, loading, title }) => {
  if (typeof image === `string`) {
    return (
      <>
        {image.startsWith(`http`) && <img css={_css} src={image} alt={image} />}
      </>
    );
  }

  const imageObj = image?.asset || image;

  if (!imageObj) {
    return <></>;
  }

  const imageData = getImage(imageObj);

  const src = imageData?.images?.fallback?.src;

  //

  return (
    <>
      {(image?.asset?.src?.includes(`.svg`) && (
        <img css={_css} src={src} alt={alt || ``} title={title || alt || ``} />
      )) || (
        <GatsbyImage
          css={_css}
          loading={loading || `eager`}
          image={imageData}
          alt={alt || ``}
          title={title || alt || ``}
        />
      )}
    </>
  );
};

Image.defaultProps = {
  _css: [],
  alt: null,
  loading: null,
  title: null
};
Image.propTypes = {
  _css: PropTypes.arrayOf(PropTypes.shape({})),
  image: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
    .isRequired,
  alt: PropTypes.string,
  loading: PropTypes.string,
  title: PropTypes.string
};

export default Image;
