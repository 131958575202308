/* eslint-disable react/prop-types */

import React from "react";
import tw, { css } from "twin.macro";
import { EASING_CUBIC } from "~styles/Animations.jsx";
import * as T from "~styles/Typography.jsx";
import { useBio } from "~hooks";
import { Image } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import { getColor } from "~utils/helpers";

import { ReactComponent as ArrowRight } from "~assets/svg/arrow-right.svg";

/** ============================================================================
 * @component
 * A card showing a specialist's name, surname and image.
 */
const BioCard = ({ data: { bio } }) => {
  const key = `${bio.name}${bio.surname}`;

  // ---------------------------------------------------------------------------
  // context / ref / state

  const { setActiveBio } = useBio();

  // ---------------------------------------------------------------------------
  // lifecycle

  // ---------------------------------------------------------------------------
  // render

  return (
    <button
      type="button"
      key={key}
      onClick={() => setActiveBio(bio)}
      css={[
        css`
          transition: transform 0.5s ${EASING_CUBIC};

          p,
          svg {
            transition: transform 0.5s ${EASING_CUBIC};
          }

          ${MEDIA_QUERIES.hoverable} {
            &:hover {
              transform: translate3d(0, -0.6rem, 0);

              p:last-child {
                transform: translate3d(-0.75rem, 0, 0);
              }

              svg {
                // transform: translate3d(-0.5rem, 0, 0);

                path {
                  fill: ${getColor(`blue-turq`)};
                }
              }
            }
          }
        `,
        tw`relative block bg-grey text-left`
      ]}
    >
      <Image image={bio.image} />

      <div css={[tw`w-full relative flex flex-col items-end p-6`]}>
        <T.Head _css={[tw`w-full relative block mt-2 mb-3`]} font="5">
          {`${bio.title} ${bio.name} ${bio.surname}` || `Dr Lorem Ipsum`}
        </T.Head>

        <ArrowRight
          css={[tw`w-6 relative mt-3`]}
          fill={getColor(`blue-deep`)}
        />
      </div>
    </button>
  );
};
export default BioCard;
