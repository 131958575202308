/* eslint-disable no-new */
/* eslint-disable react/prop-types */

import React, { useEffect } from "react";
import tw, { css } from "twin.macro";
import { useApp, useWindowDimensions } from "~hooks";
import * as A from "~styles/Animations.jsx";

import { ReactComponent as Close } from "~assets/svg/close.svg";

const Locator = () => {
  const { locatorActive, setLocatorActive } = useApp();

  const { screen } = useWindowDimensions();

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: `en`,
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT
      },
      `google_translate_element`
    );
  };

  useEffect(() => {
    if (screen === null || screen === `xs` || screen === `sm`) {
      return;
    }

    const addScript = document.createElement(`script`);

    addScript.setAttribute(
      `src`,
      `//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit`
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, [screen]);

  //

  if (screen === null || screen === `xs` || screen === `sm`) {
    return <></>;
  }

  //

  return (
    <div
      css={[
        css`
          transition: opacity 0.3s ${A.EASING_CUBIC},
            transform 0.3s ${A.EASING_CUBIC};

          opacity: ${locatorActive ? 1 : 0};
          transform: translate3d(0, ${locatorActive ? `0` : `-1rem`}, 0);
          pointer-events: ${locatorActive ? `auto` : `none`};

          display: flex;
          align-items: center;
          justify-content: center;

          #google_translate_element {
            width: 100% !important;

            .goog-logo-link {
              display: block !important;
              margin: 0 1rem !important;
            }

            .goog-te-combo {
              margin-right: 0.5rem !important;
            }

            .goog-te-gadget {
              width: 100% !important;
              display: flex !important;
              align-items: center;
            }
          }
        `,

        tw`w-screen h-12 fixed top-0 right-0 left-0 z-50 hidden md:flex items-center px-8 bg-black`
      ]}
    >
      <div id="google_translate_element" />

      <div
        css={[
          css`
            //
          `,
          tw`absolute top-0 right-0 bottom-0 z-10 flex items-center justify-end`
        ]}
      >
        <button
          type="button"
          css={[tw`w-12 h-12 relative z-10 block p-1`]}
          onClick={() => setLocatorActive(false)}
        >
          <div
            css={[tw`w-full h-full relative flex items-center justify-center`]}
          >
            <Close css={[tw`w-3 h-3 relative block`]} fill="white" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default Locator;
