import React from "react";
import tw, { css } from "twin.macro";
import PropTypes from "prop-types";
import { remToPx } from "~utils/helpers";

export const GRID_COLUMNS = 12;
export const GRID_GAP_REM = 1;
export const GRID_GAP_PX = remToPx(GRID_GAP_REM);
export const GRID_MAX_WIDTH_PX = 1800;
export const GRID_PADDING_REM = 5;
export const GRID_PADDING_PX = remToPx(GRID_PADDING_REM);

/**
 * -----------------------------------------------------------------------------
 * Receive a CSS grid wrapper to style guide spec.
 * @param  {object} _css      Additional Emotion/Tailwind CSS
 * @param  {node}   children  Inner JSX
 * @param  {string} node      Wrapper JSX node type (defaults to <div>)
 * @return {node}             The resulting CSS grid node
 */
const Grid = ({ _css, children, node }) => {
  const G = `${node}`;

  return (
    <G
      css={[
        ..._css,
        css`
          grid-template-columns: repeat(${GRID_COLUMNS}, minmax(0, 1fr));

          // todo: as mobile variables
          padding-right: 2rem;
          padding-left: 2rem;
          gap: 0 0.25rem;

          @media screen and (min-width: 1025px) {
            max-width: ${GRID_MAX_WIDTH_PX}px;
            gap: 0 ${GRID_GAP_REM}rem;
            padding-right: ${GRID_PADDING_REM}rem;
            padding-left: ${GRID_PADDING_REM}rem;
          }
        `,
        tw`w-full relative grid mr-auto ml-auto`
      ]}
    >
      {children}
    </G>
  );
};

Grid.defaultProps = {
  _css: [],
  node: `div`
};

Grid.propTypes = {
  _css: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.node.isRequired,
  node: PropTypes.string
};

export default Grid;
