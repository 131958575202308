/* eslint-disable import/prefer-default-export */

import { PropTypes } from "prop-types";
import { css } from "twin.macro";

export const EASING_CUBIC = `cubic-bezier(0.215, 0.61, 0.355, 1)`;

const KEYFRAMES = {
  appear: `
    backface-visibility: hidden;
    opacity: 0;
    transform: translate3d(0, 1rem, 0);

    @keyframes appear {
      0% {
        opacity: 0;
        transform: translate3d(0, 1rem, 0);
      }
      100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  `,
  appearSlight: `
    backface-visibility: hidden;
    opacity: 0;
    transform: translate3d(0, 0.5rem, 0);

    @keyframes appearSlight {
      0% {
        opacity: 0;
        transform: translate3d(0, 0.5rem, 0);
      }
      100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  `,
  appearDown: `
    backface-visibility: hidden;
    opacity: 0;
    transform: translate3d(0, -1rem, 0);

    @keyframes appearDown {
      0% {
        opacity: 0;
        transform: translate3d(0, -1rem, 0);
      }
      100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  `,
  appearDownSlight: `
    backface-visibility: hidden;
    opacity: 0;
    transform: translate3d(0, -0.5rem, 0);

    @keyframes appearDownSlight {
      0% {
        opacity: 0;
        transform: translate3d(0, -0.5rem, 0);
      }
      100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  `,
  appearLeft: `
    backface-visibility: hidden;
    opacity: 0;
    transform: translate3d(1rem, 0, 0);

    @keyframes appearLeft {
      0% {
        opacity: 0;
        transform: translate3d(1rem, 0, 0);
      }
      100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  `,
  appearLeftSlight: `
    backface-visibility: hidden;
    opacity: 0;
    transform: translate3d(0.5rem, 0, 0);

    @keyframes appearLeftSlight {
      0% {
        opacity: 0;
        transform: translate3d(0.5rem, 0, 0);
      }
      100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  `,
  appearRight: `
    backface-visibility: hidden;
    opacity: 0;
    transform: translate3d(-1rem, 0, 0);

    @keyframes appearRight {
      0% {
        opacity: 0;
        transform: translate3d(-1rem, 0, 0);
      }
      100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  `,
  appearRightSlight: `
    backface-visibility: hidden;
    opacity: 0;
    transform: translate3d(-0.5rem, 0, 0);

    @keyframes appearRightSlight {
      0% {
        opacity: 0;
        transform: translate3d(-0.5rem, 0, 0);
      }
      100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  `,
  appearSpin: `
    backface-visibility: hidden;
    opacity: 0;
    transform: rotate(-359deg);

    @keyframes appearSpin {
      0% {
        opacity: 0;
        transform: rotate(-359deg);
      }
      100% {
        opacity: 1;
        transform: rotate(0);
      }
    }
  `,
  appearSpinSlight: `
    backface-visibility: hidden;
    opacity: 0;
    transform: rotate(-180deg);

    @keyframes appearSpinSlight {
      0% {
        opacity: 0;
        transform: rotate(-180deg);
      }
      100% {
        opacity: 1;
        transform: rotate(0);
      }
    }
  `,
  focus: `
    -webkit-perspective: 1000;
    -webkit-font-smoothing: subpixel-antialiased;
    backface-visibility: hidden;
    font-smooth: always !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    will-change: filter;

    filter: blur(2px);
    opacity: 0;

    @keyframes focus {
      0% {
        filter: blur(4px);
        opacity: 0;
      }
      100% {
        filter: blur(.0px);
        opacity: 1;
      }
    }
  `,
  fullRotate: `
    backface-visibility: hidden;
    transform: rotate(0);

    @keyframes fullRotate {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(359deg);
      }
    }
  `,
  pulse: `
    transform: scale(1);
    opacity: 0;

    @keyframes pulse {
      0% {
        transform: scale(1);
        opacity: 0;
      }
      50% {
        transform: scale(1.5);
        opacity: 1;
      }
      100% {
        transform: scale(2);
        opacity: 0;
      }
    }
  `,
  pulseIn: `
    transform: scale(0.8);
    opacity: 0;

    @keyframes pulseIn {
      0% {
        transform: scale(0.8);
        opacity: 0;
      }
      50% {
        transform: scale(1.05);
        opacity: 0.5;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }
  `,
  pulseSlight: `
    transform: scale(1);
    opacity: 0;

    @keyframes pulseSlight {
      0% {
        transform: scale(1);
        opacity: 0;
      }
      50% {
        transform: scale(1.25);
        opacity: 1;
      }
      100% {
        transform: scale(1.5);
        opacity: 0;
      }
    }
  `
};

export const Keyframes = (id, { delay, settings }) => css`
  ${KEYFRAMES[id]}
  animation: ${id} ${settings};
  animation-delay: ${delay || 0};
`;

export const Appear = ({
  delay = `0`,
  settings = `0.3s ${EASING_CUBIC} forwards`
}) =>
  Keyframes(`appear`, {
    delay,
    settings
  });

export const AppearDown = ({
  delay = `0`,
  settings = `0.3s ${EASING_CUBIC} forwards`
}) =>
  Keyframes(`appearDown`, {
    delay,
    settings
  });

Keyframes.propTypes = {
  id: PropTypes.string.isRequired
};
