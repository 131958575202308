import React from "react";
import { css } from "twin.macro";
import { Global } from "@emotion/react";
import PropTypes from "prop-types";
import { useApp, useBio } from "~hooks";
import * as A from "~styles/Animations.jsx";
import { Bio, Footer, Header, Locator, Nav, Notifier } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const Layout = ({ _css, children }) => {
  const { locatorActive } = useApp();
  const { visible } = useBio();

  //

  return (
    <>
      <Global
        styles={[
          css`
            html,
            body {
              width: 100%;
              height: 100%;
            }

            a:focus,
            button:focus,
            input:focus,
            textarea:focus {
              outline: none;
            }

            input,
            textarea {
              appearance: none;
              border-radius: 0 !important;
            }
          `
        ]}
      />

      <Nav />

      <Locator />
      <Notifier />
      <Bio />

      <Header />

      <main
        id="layout"
        css={[
          ..._css,
          css`
            transition: transform 0.5s ${A.EASING_CUBIC};
            overflow-x: hidden;
            ${MEDIA_QUERIES.desktop} {
              transform: translate3d(
                ${visible ? `-12.5vw` : `0`},
                ${locatorActive ? `3rem` : `0`},
                0
              );
            }
          `
        ]}
      >
        {children}
      </main>

      <Footer />
    </>
  );
};

Layout.defaultProps = {
  _css: []
};

Layout.propTypes = {
  _css: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.node.isRequired
};

export default Layout;
