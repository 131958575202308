/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import tw, { css } from "twin.macro";
import * as A from "~styles/Animations.jsx";

const LineSwipe = ({ color = `white`, delay = 0, duration = 1000 }) => (
  <div
    css={[
      css`
        @keyframes line-swipe {
          from {
            transform: scaleX(0);
          }
          to {
            transform: scaleX(1) scaleY(1.5);
          }
        }

        animation: ${duration}ms ${A.EASING_CUBIC} line-swipe forwards;
        animation-delay: ${delay}ms;
        transform: scaleX(0);

        height: 1px;
        transform-origin: 0 50%;
        background: ${color || `#84bbd9`};
      `,
      tw`w-full relative block pointer-events-none`
    ]}
  />
);

export default LineSwipe;
