/* eslint-disable react/prop-types */

import React from "react";
import tw, { css } from "twin.macro";
import { useApp } from "~hooks";
import { EASING_CUBIC } from "~styles/Animations.jsx";
import { Button, ScreenHeight } from "~components";
import Grid from "~styles/Grid.jsx";
import * as T from "~styles/Typography.jsx";
import { getColor } from "~utils/helpers";
import { Go } from "~components";
import { ReactComponent as ArrowRight } from "~assets/svg/arrow-right.svg";

const Nav = () => {
  const { menuActive, setMenuActive } = useApp();

  return (
    <ScreenHeight
      _css={[
        css`
          transition: transform 0.5s ${EASING_CUBIC};
          transform: translate3d(${menuActive ? 0 : `-100vw`}, 0, 0);
        `,
        tw`w-screen fixed top-0 right-0 bottom-0 left-0 z-40 md:hidden pt-16 pb-16 bg-black`
      ]}
    >
      <Grid _css={[tw`h-full pt-12`]}>
        <div
          css={[
            tw`col-span-12 h-full relative flex flex-col justify-between text-white`
          ]}
        >
          <div>
            <></>
          </div>

          <ul>
            <li css={[tw`relative flex items-center border-t`]}>
              <Go
                to="/about-us"
                _css={[tw`h-12 items-center w-full flex justify-between`]}
                onClick={() => setMenuActive(false)}
              >
                <T.Caption>About us</T.Caption>

                <ArrowRight css={[tw`w-6 relative`]} fill={getColor(`white`)} />
              </Go>
            </li>

            <li css={[tw`relative flex items-center border-t`]}>
              <Go
                to="/our-team"
                _css={[tw`h-12 items-center w-full flex justify-between`]}
                onClick={() => setMenuActive(false)}
              >
                <T.Caption>Our team</T.Caption>

                <ArrowRight css={[tw`w-6 relative`]} fill={getColor(`white`)} />
              </Go>
            </li>

            <li css={[tw`relative flex items-center border-t`]}>
              <Go
                to="/frc-research"
                _css={[tw`h-12 items-center w-full flex justify-between`]}
                onClick={() => setMenuActive(false)}
              >
                <T.Caption>FRC research</T.Caption>

                <ArrowRight css={[tw`w-6 relative`]} fill={getColor(`white`)} />
              </Go>
            </li>

            <li css={[tw`relative flex items-center border-t`]}>
              <Go
                to="/our-services"
                _css={[tw`h-12 items-center w-full flex justify-between`]}
                onClick={() => setMenuActive(false)}
              >
                <T.Caption>Our services</T.Caption>

                <ArrowRight css={[tw`w-6 relative`]} fill={getColor(`white`)} />
              </Go>
            </li>

            <li css={[tw`relative flex items-center border-t`]}>
              <Go
                to="/costs"
                _css={[tw`h-12 items-center w-full flex justify-between`]}
                onClick={() => setMenuActive(false)}
              >
                <T.Caption>Costs</T.Caption>

                <ArrowRight css={[tw`w-6 relative`]} fill={getColor(`white`)} />
              </Go>
            </li>
          </ul>

          <div>
            <Go to="/get-started" onClick={() => setMenuActive(false)}>
              <Button _css={[tw`w-2/3 h-12 mb-4`]} text="Get Started" small />
            </Go>
            <Go to="/dr-referral" onClick={() => setMenuActive(false)}>
              <Button _css={[tw`w-2/3 h-12`]} text="Dr Referral" small />
            </Go>
          </div>
        </div>
      </Grid>
    </ScreenHeight>
  );
};

export default Nav;
