import React, { useState } from "react";
import { PropTypes } from "prop-types";
import tw, { css } from "twin.macro";
import { useWindowDimensions } from "~hooks";
import * as T from "~styles/Typography.jsx";
import { MEDIA_QUERIES } from "~utils/css";
import { getColor } from "~utils/helpers";

import { ReactComponent as ArrowUp } from "~assets/svg/arrow-up.svg";
import { ReactComponent as ArrowRight } from "~assets/svg/arrow-right.svg";
import { ReactComponent as ArrowReset } from "~assets/svg/arrow-reset.svg";
import { ReactComponent as Cross } from "~assets/svg/cross.svg";

const Button = ({
  _css,
  arrow,
  children,
  color,
  font,
  icon,
  onClick,
  small,
  text,
  transparent
}) => {
  const { screen } = useWindowDimensions();

  const [hovered, setHovered] = useState(false);
  const [hovering, setHovering] = useState(false);

  //

  const onMouseEnter = (e) => {
    if (!screen || screen === `xs` || screen === `sm`) {
      return;
    }

    setHovering(true);
    setHovered(true);
  };

  const onMouseLeave = (e) => {
    setHovering(false);
  };

  //

  let fontColor;

  switch (color) {
    case `white`:
      fontColor = `black`;
      break;

    case `black`:
      fontColor = `white`;
      break;

    default:
      break;
  }

  return (
    <button
      type="button"
      css={[
        css`
          padding: 0 ${small ? `1rem` : `1.5rem`};
          background: ${color};
          color: ${fontColor};

          ${MEDIA_QUERIES.hoverable} {
            &:hover {
              background: ${getColor(`blue-turq`)};
              color: white;

              svg {
                fill: white;
              }
            }
          }
        `,
        tw`relative block overflow-hidden`,
        ..._css
      ]}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div tw="w-full h-full relative flex items-center justify-between font-bold">
        <div
          css={[
            css`
              transition: 0.3s ease transform;
              transform: translate3d(${hovering ? `0.35rem` : `0`}, 0, 0);
              text-align: left;
            `
          ]}
        >
          {((screen === `xs` || screen === `sm`) && (
            <T.Caption font="2">{text}</T.Caption>
          )) || <T.Body font="2">{text}</T.Body>}
        </div>

        <div
          css={[
            css`
              transition: 0.3s ease transform;
              transform: translate3d(${hovering ? `-0.35rem` : `0`}, 0, 0);
            `
          ]}
        >
          {icon && icon === `cross` ? (
            <Cross
              css={[
                tw`h-8 text-h5 leading-none`,
                small ? tw`w-3 ml-3` : tw`w-4 ml-6`
              ]}
              stroke={hovering ? `white` : fontColor}
            />
          ) : (
            <>
              {(!arrow || arrow === `right`) && (
                <ArrowRight
                  css={[small ? tw`w-4 ml-4` : tw`w-6 ml-6`]}
                  fill={hovering ? `white` : fontColor}
                />
              )}

              {arrow === `up` && (
                <ArrowUp
                  css={[small ? tw`w-1 ml-4` : tw`w-5 ml-6`]}
                  fill={hovering ? `white` : fontColor}
                />
              )}

              {arrow === `down` && (
                <ArrowUp
                  css={[
                    css`
                      transform: scaleY(-1);
                    `,
                    small ? tw`w-1 ml-4` : tw`w-5 ml-6`
                  ]}
                  fill={hovering ? `white` : fontColor}
                />
              )}

              {arrow === `reset` && (
                <ArrowReset
                  css={[small ? tw`w-2 ml-2` : tw`w-4 ml-4`]}
                  fill={hovering ? `white` : fontColor}
                />
              )}
            </>
          )}
        </div>
      </div>
    </button>
  );
};

Button.defaultProps = {
  _css: [],
  arrow: null,
  children: null,
  color: `white`,
  font: `default`,
  icon: `arrow`,
  onClick: () => {},
  small: false,
  text: `Submit`,
  transparent: false
};

Button.propTypes = {
  _css: PropTypes.arrayOf(PropTypes.shape({})),
  arrow: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  icon: PropTypes.string,
  font: PropTypes.string,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  text: PropTypes.string,
  transparent: PropTypes.bool
};

export default Button;
