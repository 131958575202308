/* eslint-disable react/prop-types */

import React from "react";
import tw, { css } from "twin.macro";
import { graphql, useStaticQuery } from "gatsby";
import { useNotification } from "~hooks";
import { EASING_CUBIC, Keyframes } from "~styles/Animations.jsx";
import * as T from "~styles/Typography.jsx";
import { Go } from "~components";

import { ReactComponent as ArrowRight } from "~assets/svg/arrow-right.svg";
import { ReactComponent as Close } from "~assets/svg/close.svg";

const Notifier = () => {
  const { sanityGlobals } = useStaticQuery(graphql`
    query Notifier {
      sanityGlobals {
        notificationLink {
          text
          url
        }
      }
    }
  `);

  const { active, hide } = useNotification();

  //

  return (
    <div
      css={[
        css`
          transition: 0.3s ${EASING_CUBIC} transform;

          pointer-events: ${active ? `auto` : `none`};
          transform: translate3d(${active ? `0` : `100%`}, 0, 0);
        `,
        tw`fixed hidden md:block right-0 bottom-0 z-40 pointer-events-none`
      ]}
    >
      <div
        css={[
          css`
            ${Keyframes(`appearLeft`, {
              delay: `1000ms`,
              settings: `0.5s ${EASING_CUBIC} forwards`
            })}
          `,
          tw`pointer-events-auto cursor-pointer`
        ]}
      >
        <Go
          to={sanityGlobals?.notificationLink?.url}
          onClick={hide}
          _css={[
            tw`relative mb-12 pt-5 pr-5 pb-5 pl-5 flex items-center bg-turqouise text-white`
          ]}
        >
          <div
            css={[
              tw`w-6 h-6 relative flex items-center justify-center rounded-full overflow-hidden border-white border`
            ]}
          >
            <T.Caption>i</T.Caption>
          </div>

          <T.Body _css={[tw`mr-6 ml-4`]} font="2">
            {sanityGlobals?.notificationLink?.text}
          </T.Body>

          <ArrowRight css={[tw`w-5`]} fill="white" />
        </Go>
        <div
          css={[
            tw`absolute right-4 z-10 border-black border border-b-0 bg-white`,
            css`
              top: -2.08rem;
            `
          ]}
        >
          <button
            type="button"
            css={[tw`w-8 h-8 relative block`]}
            onClick={hide}
          >
            <div
              css={[
                tw`w-full h-full relative flex items-center justify-center`
              ]}
            >
              <Close
                css={[
                  css`
                    width: 0.9rem;
                  `
                ]}
              />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Notifier;
