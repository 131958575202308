/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useRef, useState } from "react";
import tw from "twin.macro";
import { Button, Grid } from "~components";
import * as T from "~styles/Typography.jsx";

const Newsletter = ({ content, heading }) => {
  const submitRef = useRef();

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  //

  const submitProxy = () => {
    if (submitRef?.current) {
      submitRef.current.click();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      typeof window !== `undefined` &&
      window.location.href.includes(`localhost:8000`)
    ) {
      setSubmitting(true);

      setTimeout(() => {
        setSubmitting(false);
        setSubmitted(true);
      }, 3000);

      return false;
    }

    if (submitting || submitted) {
      return false;
    }

    setSubmitting(true);

    // const formData = new FormData(e.target);

    // fetch ...

    return false;
  };

  //

  return (
    <section tw="w-full relative block pt-16 pb-24">
      <Grid>
        <article tw="col-span-5 text-white">
          <T.Head font="3" level="2" styles={[tw`mt-4 mb-6`]}>
            Sign up to our newsletter
          </T.Head>

          <T.Body font="1">
            We promise not to overload you with daily emails about trends and
            boring.
          </T.Body>
        </article>

        <form tw="col-span-6 col-start-7">
          <input
            tw="w-full h-16 relative block mb-4 px-4 bg-white"
            placeholder="Full Name*"
            type="text"
          />

          <input
            tw="w-full h-16 relative block mb-4 px-4 bg-white"
            placeholder="Email*"
            type="email"
          />

          <div tw="w-full flex relative justify-end">
            <Button color="white" styles={[tw`w-2/6`]} text="Sign Up" />
          </div>
        </form>
      </Grid>
    </section>
  );
};

export default Newsletter;
