/* eslint-disable import/prefer-default-export */

export const MEDIA_QUERIES = {
  desktop: `@media screen and (min-width: 1025px)`,
  large: `@media screen and (min-width: 1280px)`,
  wide: `@media screen and (min-width: 1440px)`,
  ultrawide: `@media screen and (min-width: 1920px)`,
  extremewide: `@media screen and (min-width: 2560px)`,
  hoverable: `@media not all and (hover: none)`
};
