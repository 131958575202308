/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from "react";
import tw, { css } from "twin.macro";
import Grid from "~styles/Grid.jsx";
import { EASING_CUBIC, Keyframes } from "~styles/Animations.jsx";
import { ArticleCard, Button } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

import { ReactComponent as ArrowUp } from "~assets/svg/arrow-up.svg";

const ARTICLE_START_SIZE = 9;
const ARTICLE_BATCH_SIZE = 3;

const ArticleGrid = ({ articles, filters }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [activeArticles, setActiveArticles] = useState(articles);
  const [isFiltered, setIsFiltered] = useState(false);
  const [loadThreshold, setLoadThreshold] = useState(ARTICLE_START_SIZE);
  const [masonry, setMasonry] = useState([[], [], []]);

  // ---------------------------------------------------------------------------
  // variables

  const cardWrapperCSS = [
    css`
      ${Keyframes(`appearDown`, { settings: `0.5s ${EASING_CUBIC} forwards` })};
    `,
    tw`mb-5`
  ];

  // ---------------------------------------------------------------------------
  // methods

  const backToTop = () => {
    if (typeof window === `undefined`) {
      return;
    }

    window.scrollTo({
      top: 0,
      behavior: `smooth`
    });
  };

  const loadMore = () => {
    setLoadThreshold(loadThreshold + ARTICLE_BATCH_SIZE);
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!filters?.activeAuthor && !filters?.activeTopic) {
      return;
    }

    if (!isFiltered) {
      setIsFiltered(filters?.activeAuthor || filters?.activeTopic);
    }

    const parsedAuthor = filters?.activeAuthor?.toLowerCase();
    const parsedTopic = filters?.activeTopic?.toLowerCase();

    const filteredArticles = [];

    articles?.forEach((article) => {
      let authorMatch =
        !parsedAuthor || parsedAuthor?.toLowerCase() === `by author`;

      if (!authorMatch) {
        authorMatch = parsedAuthor === article?.author?.toLowerCase();
      }

      let topicMatch =
        !parsedTopic || parsedTopic?.toLowerCase() === `by topic`;

      if (!topicMatch) {
        topicMatch = parsedTopic === article?.topic?.title?.toLowerCase();
      }

      if (authorMatch && topicMatch) {
        filteredArticles.push(article);
      }
    });

    setActiveArticles(filteredArticles);
  }, [filters]);

  useEffect(() => {
    // if (!activeArticles?.[0]) {
    //   return;
    // }

    const articleMasonry = [[], [], []];

    activeArticles.forEach((article, articleIndex) => {
      if (!isFiltered && articleIndex >= loadThreshold) {
        return;
      }

      articleMasonry[articleIndex % 3].push(article);
    });

    setMasonry(articleMasonry);
  }, [activeArticles, loadThreshold]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <>
      <Grid>
        <ul css={[tw`col-span-12 md:col-span-4 relative`]}>
          {masonry[0].map((article) => (
            <li key={article?.id || article?.title} css={cardWrapperCSS}>
              <ArticleCard data={article} />
            </li>
          ))}
        </ul>

        <ul css={[tw`col-span-12 md:col-span-4 relative`]}>
          {masonry[1].map((article) => (
            <li key={article?.id || article?.title} css={cardWrapperCSS}>
              <ArticleCard data={article} />
            </li>
          ))}
        </ul>

        <ul css={[tw`col-span-12 md:col-span-4 relative`]}>
          {masonry[2].map((article) => (
            <li key={article?.id || article?.title} css={cardWrapperCSS}>
              <ArticleCard data={article} />
            </li>
          ))}
        </ul>
      </Grid>

      <div
        css={[
          tw`w-screen h-16 relative flex justify-end mt-8 md:mt-12 md:mb-12 pl-8 md:pl-0`
        ]}
      >
        <Button
          arrow="down"
          _css={[
            css`
              width: calc(100% - 5rem);

              ${MEDIA_QUERIES.desktop} {
                width: 12rem;
              }

              opacity: ${isFiltered || loadThreshold > activeArticles?.length
                ? `0`
                : `1`};
              pointer-events: ${isFiltered ||
              loadThreshold > activeArticles?.length
                ? `none`
                : `auto`};
            `,
            tw`h-16 mr-3 border`
          ]}
          color="white"
          onClick={loadMore}
          text="Load more"
        />

        <button
          type="button"
          css={[
            tw`w-28 h-16 z-30 bg-black`,
            css`
              svg {
                margin: auto;
                transition: 0.3s ease transform;
              }

              ${MEDIA_QUERIES.hoverable} {
                &:hover {
                  svg {
                    transform: translate3d(-1.5rem, 0, 0);
                  }
                }
              }
            `
          ]}
          onClick={backToTop}
          label="back-to-top"
        >
          <ArrowUp
            fill="white"
            css={[
              css`
                display: absolute;
                height: 50%;
              `
            ]}
          />
        </button>
      </div>
    </>
  );
};

export default ArticleGrid;
