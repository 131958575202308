/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React from "react";
import tw, { css } from "twin.macro";
import { EASING_CUBIC } from "~styles/Animations.jsx";
import * as T from "~styles/Typography.jsx";
import { Go, Image } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import { getColor } from "~utils/helpers";

import { ReactComponent as ArrowRight } from "~assets/svg/arrow-right.svg";

/** ============================================================================
 * @component
 * A carousel of cards with imagery that expose text when hovered.
 */
const ArticleCard = ({
  data: { _type, cardImage, excerpt, slug, tag, title }
}) => {
  let urlPrefix = `frc-research`;

  switch (_type) {
    case `event`: {
      urlPrefix = `events`;
      break;
    }

    case `news`: {
      urlPrefix = `news`;
      break;
    }

    default:
      break;
  }

  //

  if (!slug?.current) {
    return <></>;
  }

  return (
    <article
      key={title}
      css={[
        css`
          //
          color: ${tag?.hex || `black`};
        `,
        tw`h-full relative flex flex-col items-stretch bg-grey`
      ]}
    >
      <div
        css={[
          css`
            min-width: 50%;
            background: ${tag?.hex || getColor(`blue-med`)};
          `,
          tw`absolute top-0 left-0 z-10 p-2 md:p-3 text-white pointer-events-none`
        ]}
      >
        <T.Head font="6">{tag?.title || `Tag`}</T.Head>
      </div>

      {cardImage && (
        <figure css={[tw`w-full relative block`]}>
          <Go to={`/${urlPrefix}/${slug.current}`}>
            <Image image={cardImage} />
          </Go>
        </figure>
      )}

      <div
        css={[
          css`
            padding-top: ${cardImage ? `0.5rem` : `2.75rem`};
            flex: 1 0 auto;

            ${MEDIA_QUERIES.desktop} {
              padding-top: ${cardImage ? `1rem` : `5rem`};
            }
          `,
          tw`relative flex flex-col items-stretch pr-6 md:pr-8 lg:pr-10 pb-6 md:pb-8 lg:pb-10 pl-6 md:pl-8 lg:pl-10`
        ]}
      >
        <header css={[tw`w-full relative mt-6 pr-8`]}>
          <Go to={`/${urlPrefix}/${slug.current}`}>
            <T.Head _css={[tw`font-bold`]} font="3">
              {title || `Lorem ipsum`}
            </T.Head>
          </Go>
        </header>

        <div
          css={[
            css`
              flex: 1 0 auto;
            `,
            tw`relative mt-4 md:mt-6 text-black`
          ]}
        >
          <T.Body font="2">{excerpt || `Lorem ipsum`}</T.Body>
        </div>

        <Go
          to={`/${urlPrefix}/${slug.current}`}
          _css={[
            css`
              &:hover {
                background: rgba(255, 255, 255, 0.125);

                p {
                  transform: translate3d(0.75rem, 0, 0);
                }
                svg {
                  transform: translate3d(-0.75rem, 0, 0);
                }
              }

              p,
              svg {
                transition: transform 0.3s ${EASING_CUBIC};
              }
            `,
            tw`w-full relative flex items-center justify-between mt-8 md:mt-6 lg:mt-10 pt-3 pb-3 border-black border-t`
          ]}
        >
          <T.Body _css={[tw`font-bold text-black`]} font="2">
            Read more
          </T.Body>

          <ArrowRight css={[tw`w-5 md:w-8`]} fill="black" />
        </Go>
      </div>
    </article>
  );
};

export default ArticleCard;
