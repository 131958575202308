/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import tw, { css, theme } from "twin.macro";
import { useApp } from "~hooks";
import * as A from "~styles/Animations.jsx";
import { MEDIA_QUERIES } from "~utils/css";

import { ReactComponent as Wave1ABlue } from "~assets/svg/header-1-shape-a-blue.svg";
import { ReactComponent as Wave1BBlue } from "~assets/svg/header-1-shape-b-blue.svg";

import { ReactComponent as Wave1AWhite } from "~assets/svg/header-1-shape-a-white.svg";
import { ReactComponent as Wave1BWhite } from "~assets/svg/header-1-shape-b-white.svg";

import { ReactComponent as Wave2A } from "~assets/svg/header-2-shape-a.svg";
import { ReactComponent as Wave2B } from "~assets/svg/header-2-shape-b.svg";

import { ReactComponent as Wave3A } from "~assets/svg/header-3-shape-a.svg";
import { ReactComponent as Wave3B } from "~assets/svg/header-3-shape-b.svg";

import { ReactComponent as Wave4A } from "~assets/svg/header-4-shape-a.svg";
import { ReactComponent as Wave4B } from "~assets/svg/header-4-shape-b.svg";

import { ReactComponent as Wave5A } from "~assets/svg/header-5-shape-a.svg";
import { ReactComponent as Wave5B } from "~assets/svg/header-5-shape-b.svg";

import { ReactComponent as Wave6A } from "~assets/svg/header-6-shape-a.svg";
import { ReactComponent as Wave6B } from "~assets/svg/header-6-shape-b.svg";

import { ReactComponent as Wave7A } from "~assets/svg/header-7-shape-a.svg";
import { ReactComponent as Wave7B } from "~assets/svg/header-7-shape-b.svg";

import { ReactComponent as Wave8A } from "~assets/svg/header-8-shape.svg";

const WaveCanvas = () => {
  const { pathname } = useApp();

  const commonCSS = [
    css`
      transform: translate3d(0, -50%, 0);
      opacity: 0;
    `
  ];

  let imagePair;
  if (
    pathname?.includes(`/gp-referral/`) ||
    pathname?.includes(`/dr-referral/`)
  ) {
    imagePair = [<Wave8A />];
  } else if (pathname?.includes(`/our-services`)) {
    imagePair = [<Wave5A />, <Wave5B />];
  } else if (
    pathname?.includes(`/understanding-your-fertility/`) ||
    pathname?.includes(`/frc-research/`)
  ) {
    imagePair = [<Wave8A />];
  } else if (pathname?.includes(`/news/`) || pathname?.includes(`/events/`)) {
    imagePair = [<Wave8A />];
  } else {
    switch (pathname) {
      case `/about-us`:
      case `/about-us/`:
      case `/resource-form/`:
      case `/patient-form/`:
      case `/resource-form`:
      case `/patient-form`:
        imagePair = [<Wave2A />, <Wave2B />];
        break;

      case `/our-team`:
      case `/our-team/`:
      case `/costs`:
      case `/costs/`:
      case `/search`:
      case `/search/`:
      case `/our-services`:
      case `/our-services/`:
      case `/gp-referral`:
      case `/dr-referral`:
      case `/gp-referral/`:
      case `/dr-referral/`:
        imagePair = [<Wave5A />, <Wave5B />];

        break;

      case `/frc-research`:
      case `/frc-research/`:
      case `/understanding-your-fertility`:
      case `/understanding-your-fertility/`:
      case `/events`:
      case `/news`:
      case `/news-and-events`:
      case `/news-and-events/`:
        imagePair = [<Wave6A />, <Wave6B />];

        break;

      default:
        imagePair = [<Wave1ABlue />, <Wave1BBlue />];
        break;
    }
  }
  //

  return (
    <div
      className="wave-canvas"
      css={[
        css`
          @keyframes wave-appear {
            from {
              transform: translate3d(0, -50%, 0);
              opacity: 0;
            }
            to {
              transform: translate3d(0, 0, 0);
              opacity: 1;
            }
          }
        `,
        tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 overflow-hidden`
      ]}
    >
      <div
        css={[
          css`
            display: none;

            ${MEDIA_QUERIES.desktop} {
              display: block;
            }
          `,
          tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 z-20`
        ]}
      >
        <div
          css={[
            ...commonCSS,
            css`
              animation: wave-appear 3000ms ${A.EASING_CUBIC} forwards;
              transform: translate3d(-50%, 0, 0);

              svg {
                width: 100%;
              }
            `,
            tw`w-full h-full relative flex flex-nowrap items-start justify-center`
          ]}
        >
          {imagePair?.[0]}
        </div>
      </div>

      <div
        css={[
          css`
            //
          `,
          tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10`
        ]}
      >
        <div
          css={[
            ...commonCSS,
            css`
              animation: wave-appear 4000ms ${A.EASING_CUBIC} forwards;
              animation-delay: 500ms;

              width: 100vw;
            `,
            tw`w-full h-full relative`
          ]}
        >
          {imagePair?.[1]}
        </div>
      </div>
    </div>
  );
};

export default WaveCanvas;
