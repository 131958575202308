/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { css } from "twin.macro";
import { useWindowDimensions } from "~hooks";

const ScreenHeight = ({ _css, children }) => {
  const { windowSize } = useWindowDimensions();

  //

  return (
    <section
      css={[
        ..._css,
        css`
          height: 100vh;
          height: ${windowSize.height}px;
        `
      ]}
    >
      {children}
    </section>
  );
};

export default ScreenHeight;
