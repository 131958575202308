/* eslint-disable react/prop-types */

import React, { useEffect, useRef, useState } from "react";
import { PropTypes } from "prop-types";
import { validateEmail } from "~utils/helpers";

const KlaviyoForm = ({ debug, listId, render }) => {
  // ===========================================================================
  // context / ref / state

  const submitRef = useRef();

  const [form, setForm] = useState({});
  const [focused, setFocused] = useState(null);
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false
  });
  const [valid, setValid] = useState(false);

  // ===========================================================================
  // variables

  const ajaxUrl = `//manage.kmail-lists.com/ajax/subscriptions/subscribe`;

  // ===========================================================================
  // methods

  const submitProxy = () => {
    if (!submitRef?.current) {
      return;
    }

    submitRef.current.click();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (status?.submitting || status?.submitted) {
      return false;
    }

    setStatus({
      ...status,
      submitting: true
    });

    if (
      typeof window === `undefined` ||
      window.location.href.includes(`localhost:8`)
    ) {
      setTimeout(() => {
        if (debug) {
          // eslint-disable-next-line no-console
          console.log(`[klaviyo]: submitted`);
        }

        setStatus({
          submitting: false,
          submitted: true
        });
      }, 3000);

      return false;
    }

    const formData = new FormData(e.target);

    fetch(ajaxUrl, {
      body: formData,
      headers: {
        Accept: `application/json`,
        "Content-Type": `application/json`
      },
      method: `post`,
      mode: `no-cors`
    }).then((response) => {
      // eslint-disable-next-line no-console
      if (debug) {
        console.log(`[klaviyo]: response: `, response);
      }

      setStatus({
        submitting: false,
        submitted: true
      });
    });

    return false;
  };

  // ===========================================================================
  // lifecycle

  useEffect(() => {
    setValid(form?.email && validateEmail(form.email));
  }, [form]);

  // ===========================================================================
  // lifecycle

  return (
    <>
      {listId && (
        <form onSubmit={onSubmit}>
          <input type="hidden" name="g" value={listId} />
          <input
            ref={submitRef}
            tw="w-0 h-0 absolute opacity-0 pointer-events-none"
            type="submit"
          />

          {render({
            data: form,
            focused,
            setFocused,
            status,
            submit: submitProxy,
            update: setForm,
            valid
          })}
        </form>
      )}
    </>
  );
};

KlaviyoForm.defaultProps = {
  debug: false
};

KlaviyoForm.propTypes = {
  debug: PropTypes.bool,
  render: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired
};

export default KlaviyoForm;
